<template>
  <b-form
    @submit.prevent="postCategoryData"
    @reset.prevent="OnReset"
  >
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Name"
          label-for="name"
          label-cols-md="4"
        >
          <b-form-input
            id="name"
            v-model="editData.name"
            type="text"
            placeholder="Name"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Slug"
          label-for="slug"
          label-cols-md="4"
        >
          <b-form-input
            id="slug"
            v-model="editData.slug"
            type="text"
            placeholder="slug"
          />
        </b-form-group>
      </b-col>
      <!-- submit and reset -->
      <b-col offset-md="4">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
        >
          Submit
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
        >
          Reset
        </b-button>
      </b-col>
    </b-row>

  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // churchData: [],
      // departmentData: [],
      // positionData: [],
      editData: {},
    }
  },
  mounted() {
    this.getCategoryData()
  },
  methods: {
    getCategoryData() {
      axios
        .get(`/categories/${this.$route.params.id}`)
        .then(res => {
          this.editData = res.data.data
        })
      // eslint-disable-next-line no-console
        .catch(err => console.log(err))
    },
    postCategoryData() {
      this.$swal({
        title: 'Please Wait..',
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 2000,
        didOpen: () => {
          this.$swal.showLoading()
        },
      }).then(
        axios.post(`/categories/${this.$route.params.id}`, this.editData).then(res => {
          this.edit = {}
          // eslint-disable-next-line no-console
          console.log('res', res)
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: 'Your data has been saved.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            this.$router.push({ name: 'category-all' })
          })
        }),
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.demo-inline-spacing > * {
    margin-top: 0.5rem;
}
</style>
